import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import portalLayoutMiddleware from "../middlewares/PortalLayoutMiddleware";
import createRootReducer from "../reducers";

const isDev =
  process.env.NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = isDev
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, portalLayoutMiddleware, routeMiddleware];

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      )
    )
  );

  return store;
}
