import { from, of } from "rxjs";
import { AuthHttp } from "@common/services";
import { map, catchError } from "rxjs/operators";
import { openNotification } from "@common/helpers";
import { getRequestParams } from "@helpers";

export function getSSOProvider() {
  const url = `/sso/identity-provider`;
  return from(AuthHttp.get(url)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}
/**
 * POST /sso/identify-provider
 *
 * @param body
 * {

    "alias": "123",

    "enabled": true,

        "tenantId": "6d690505-437a-468c-a17a-407c54fbec7d",

        "clientSecret": "aI~8Q~x190UB3mdPyCanVT0mi_4DK5jLdQephcY6",

        "clientId": "9d1180fd-4d37-4be6-a171-e7a5439007f5",

        "tokenUrl": "https://login.microsoftonline.com/f01e930a-b52e-42b1-b70f-a8882b5d043b/oauth2/v2.0/token",

        "authorizationUrl": "https://login.microsoftonline.com/f01e930a-b52e-42b1-b70f-a8882b5d043b/oauth2/v2.0/authorize"

}
 */
export function createSSOProvider(body) {
  const url = `/sso/identity-provider`;
  return from(AuthHttp.post(url, body)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * PUT /sso/identify-provider
 *
 * @param body
 * {

    "alias": "123",

    "enabled": true,

        "tenantId": "6d690505-437a-468c-a17a-407c54fbec7d",

        "clientSecret": "aI~8Q~x190UB3mdPyCanVT0mi_4DK5jLdQephcY6",

        "clientId": "9d1180fd-4d37-4be6-a171-e7a5439007f5",

        "tokenUrl": "https://login.microsoftonline.com/f01e930a-b52e-42b1-b70f-a8882b5d043b/oauth2/v2.0/token",

        "authorizationUrl": "https://login.microsoftonline.com/f01e930a-b52e-42b1-b70f-a8882b5d043b/oauth2/v2.0/authorize"

}
 */
export function updateSSOProvider(body) {
  const url = `/sso/identity-provider`;
  return from(AuthHttp.put(url, body)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

export function getSSOUsers() {
  const url = `/sso/user-azure`;
  return from(AuthHttp.get(url)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}
