import { from, of } from "rxjs";
import { AuthHttp } from "@common/services";
import { map, catchError } from "rxjs/operators";
import { openNotification } from "@common/helpers";
import { getRequestParams } from "@helpers";

export function getUserPermissions() {
  const url = `/users/permissions`;
  return from(AuthHttp.get(url)).pipe(
    catchError((e) => {
      return of([]);
    })
  );
}
