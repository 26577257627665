import React from "react";
import { Link } from "react-router-dom";
import logo from "@assets/images/icons/logo.svg";
import { Dropdown, Menu } from "antd";
import { MENU_NAVIGATION } from "@constants/Menu";
import appSwitchIcon from "@assets/images/icons/app-switch-icon.svg";

const SidebarLogo = () => {
  const menuNavigation = (
    <Menu>
      {MENU_NAVIGATION.map((item) => (
        <Menu.Item key={item.id}>
          <img src={item.icon} />
          &nbsp;
          <a href={item.url}>{item.text}</a>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="gx-layout-sider-header akc-sider-header">
      <Link to="/" className="gx-site-logo">
        <img alt="" src={logo} height="27px" />
      </Link>
    </div>
  );
};

export default SidebarLogo;
