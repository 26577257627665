import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
// Add this import:
import { getUserProfile, getUserPermissions } from "@api";
import { openNotification, getAccessToken } from "@common/helpers";
import { AppContainer } from "react-hot-loader";
import configureStore from "./common/appRedux/store";
import {
  loadCurrentUserInfo,
  loadUserProfileDetail,
  selectBU,
  selectCostCenter,
  selectMultiCostCenter,
  setPermission4BU,
} from "./common/appRedux/actions";
import i18n from "i18next";
import { logOut, authenticationInit, getLoginUserInfo } from "@common/auth";
import { history } from "./common/appRedux/store";

const store = configureStore({
  user: getLoginUserInfo(),
});

// Wrap the rendering in a function:
const render = (Component) => {
  store.dispatch(loadCurrentUserInfo());
  ReactDOM.render(
    <AppContainer>
      <Component store={store} />
    </AppContainer>,
    document.getElementById("root")
  );
};

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}

const main = () => {
  // Do this once
  registerServiceWorker();

  // Render once
  render(NextApp);
};

authenticationInit().subscribe(
  // initial behavior
  () => {
    if (!localStorage.getItem("companyId")) history.push("/");
    if (getAccessToken()) {
        getUserProfile().subscribe(
          (res) => {
            i18n.changeLanguage(res.language || "en_US");
            const defaultCCs = res.businessUnits
              ?.filter((item) => item.isDefaultBu)
              ?.map((item) => item.costCenterId);
            const profileCC = res.costCenter.map(item => item.id);
            const profileBU = res.businessUnits.map((item) => item.id);
            const selectedCC =
               localStorage.getItem("costCenterId") && localStorage.getItem("costCenterId").split(",").every(item => profileCC.includes(item)) ? localStorage.getItem("costCenterId"): defaultCCs?.length > 0 ? defaultCCs[0] : res.costCenter[0].id;
            const selectBUInit =
              localStorage.getItem("businessUnit") &&
              localStorage.getItem("businessUnit")
                .split(",").every(item => profileBU.includes(item)) ? localStorage.getItem("businessUnit")
                : res.businessUnits?.find((item) => item.isDefaultBu)?.id ||
                  res.businessUnits?.filter(
                    (item) => item.costCenterId === selectedCC
                  )[0].id;
            localStorage.setItem("defaultBU", selectBUInit);
            localStorage.setItem(
              "businessUnitList",
              JSON.stringify(res.businessUnits)
            );
            localStorage.setItem(
              "businessUnit",
              selectBUInit
            );
            localStorage.setItem("costCenterId", selectedCC);
            localStorage.setItem("companyId", res.company.id);

            store.dispatch(loadUserProfileDetail(res));
            store.dispatch(selectBU([selectBUInit]));
            store.dispatch(selectCostCenter(selectedCC));
            store.dispatch(selectMultiCostCenter([]));
            if (!res?.companyOwner) {
              getUserPermissions().subscribe((res) => {
                store.dispatch(
                  setPermission4BU(
                    res.data.filter((item) => item.scope == "Edit")
                  )
                );
              });
            } else {
               store.dispatch(
                 setPermission4BU([])
               );
            }
            main();
          },
          (err) => {
            if (err?.response?.data === "Token Expired") {
            } else {
              logOut();
            }
          }
        );
    }
  },
  // handle initial login error
  (err) => {}
);
