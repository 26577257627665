import React from "react";
import { PUBLIC_URL, LANDING_URL } from "@common/config";
import { cleanURL } from "@util";
import asyncComponentLoader from "@util/asyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";
import { SSO_ACCESS_TOKEN, SSO_REFRESH_TOKEN } from "@constants/sso/type";

const App = ({ match }) => {
  const matchUrl = cleanURL(`${match.url}${PUBLIC_URL}`);
  const asyncComponent = (path, prefix) => {
    return asyncComponentLoader(() => import(`${path}`), {
      prefixRoute: cleanURL(`${matchUrl}/${prefix}`),
    });
  };

  //SSO
  const dataSSO = [
    { id: "ifr_security", url: process.env.REACT_APP_SECURITY_ASSURANCE },
    { id: "ifr_expense", url: process.env.REACT_APP_EXPENSE },
    { id: "ifr_portal", url: process.env.REACT_APP_PORTAL },
    { id: "ifr_ops", url: process.env.REACT_APP_CLOUD_OPERATION },
  ];

  const token = [
    {
      key: SSO_ACCESS_TOKEN,
      value: localStorage.getItem(SSO_ACCESS_TOKEN),
    },
    {
      key: SSO_REFRESH_TOKEN,
      value: localStorage.getItem(SSO_REFRESH_TOKEN),
    },
  ];

  function ifOnload(id, url) {
    if (
      localStorage.getItem(SSO_ACCESS_TOKEN) &&
      localStorage.getItem(SSO_REFRESH_TOKEN)
    ) {
      document.getElementById(id).contentWindow.postMessage(token, `${url}/`);
    }
  }

  return (
    <div className="gx-page-layout portals">
      <Switch>
        <Route path={matchUrl} exact={true}>
          <Redirect to={cleanURL(`${matchUrl}/user-area`)} />
        </Route>
        <Route
          path={cleanURL(`${matchUrl}/sample`)}
          component={asyncComponent("./Sample")}
        />
        <Route
          path={cleanURL(`${matchUrl}/user-area`)}
          component={asyncComponent("./UserArea")}
        />
        <Route
          path={cleanURL(`${matchUrl}/user-profile`)}
          component={asyncComponent("./UserProfile")}
        />
        <Route
          path={"*"}
          component={() => (window.location.href = `${LANDING_URL}/404`)}
        />
      </Switch>
      {dataSSO &&
        dataSSO.map((item) => {
          return (
            <iframe
              style={{ display: "none" }}
              id={item.id}
              onLoad={() => ifOnload(item.id, item.url)}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
              src={`${item.url}/getlocalstorage.html`}
            />
          );
        })}
    </div>
  );
};

export default App;
