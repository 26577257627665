import { Layout } from "antd";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import App from "@pages";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
const { Content } = Layout;

const MainApp = (props) => {
  const match = useRouteMatch();

  return (
    <Layout className="gx-app-layout">
      <I18nextProvider i18n={i18n}>
        <Sidebar />
        <Layout>
          <Topbar />
          <Content
            className={"gx-layout-content gx-container-wrap "}
            id="body-content"
          >
            <App match={match} />
          </Content>
        </Layout>
      </I18nextProvider>
    </Layout>
  );
};
export default MainApp;
