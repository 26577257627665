import { from, of } from "rxjs";
import { AuthHttp } from "@common/services";
// import { map, catchError } from 'rxjs/operators';
// import { openNotification } from '@common/helpers';
import { getRequestParams } from "@helpers";

/**
 * GET /api/users
 * Get user list
 * @param page page index (Optional)
 * @param limit page size (Optional)
 * @param keyword search by keyword (Optional)
 * @param sortBy sort by field (Optional)
 * @param sortOrder sort by ASC/ DESC (Optional)
 */

export function getLogList(params) {
  const requestParams = getRequestParams(params);
  const url = `/logs/user?${requestParams}`;

  return from(AuthHttp.get(url));
}

export function getAuditlogList(params) {
  const requestParams = getRequestParams(params);
  const url = `/logs/admin?${requestParams}`;

  return from(AuthHttp.get(url));
}
