import { toast, ToastContainer } from "react-toastify";
import { Button, notification } from "antd";
import React, { Component } from "react";

export function showSuccessToast(msg) {
  toast.success(msg, {
    position: "bottom-right",
  });
}

export function showErrorToast(msg) {
  toast.error(msg, {
    position: "bottom-right",
  });
}

export function openNotification(
  type,
  message,
  isConfirm = false,
  handleOnclick
) {
  if (isConfirm) {
    const key = `open${Date.now()}`;
    notification[type]({
      message: "Warning!",
      description: `${message}`,
      duration: 3,
      key,
      placement: "topLeft",
      onClose: handleOnclick,
    });
  } else {
    notification[type]({
      message: localStorage.getItem("i18nextLng") === 'en_US' ? 'Notification' : '通知',
      description: `${message}`,
    });
  }
}

export { ToastContainer };

export function showSpinner() {
  const spinnerElm = document.getElementById("spinner-elm");
  if (spinnerElm) {
    spinnerElm.style.display = "flex";
  }
}

export function hideSpinner() {
  const spinnerElm = document.getElementById("spinner-elm");
  if (spinnerElm) {
    spinnerElm.style.display = "none";
  }
}
