import { toggleCollapsedSideNav } from "@appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "@constants/ThemeSetting";
import { Drawer, Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import SidebarContent from "./SidebarContent";

const { Sider } = Layout;

const Sidebar = () => {
  const dispatch = useDispatch();

  const { themeType, width, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const { navCollapsed } = useSelector(({ common }) => common);
  console.log(navCollapsed);
  const user = useSelector(({ user }) => user);

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  return (
    <Sider
      className="gx-app-sidebar gx-layout-sider-dark"
      trigger={null}
      collapsed={
        (width < TAB_SIZE
          ? false
          : navStyle === NAV_STYLE_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) || navCollapsed
      }
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      collapsible={true}
    >
      {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
        <Drawer
          className={"gx-drawer-sidebar"}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          visible={navCollapsed}
        >
          <SidebarContent user={user} />
        </Drawer>
      ) : (
        <SidebarContent user={user} />
      )}
    </Sider>
  );
};

export default Sidebar;
