import { from, of } from "rxjs";
import { AuthHttp } from "@common/services";
import { map, catchError } from "rxjs/operators";
import { openNotification } from "@common/helpers";
import { getRequestParams } from "@helpers";

/**
 * GET /api/cost-centers
 * Get cost center list
 */
export function getCostCentersList(params) {
  const requestParams = getRequestParams(params);
  const url = `/cost-centers/summary?${requestParams}`;
  return from(AuthHttp.get(url)).pipe(
    map(({ data }) => {
      return { data: data.content, count: data.totalElements };
    }),
    catchError((error) => {
      return of({ data: [], count: 0 });
    })
  );
}

/**
 * GET /api/cost-centers/{id}
 * Get cost center details
 * @param id ID of cost center (Required)
 */
export function getCostCenterDetails(id) {
  const url = `/cost-centers/${id}`;
  return from(AuthHttp.get(url)).pipe(
    map(({ data }) => {
      return data;
    }),
    catchError((error) => {
      return of({ data: [], count: 0 });
    })
  );
}

/**
 * Update /api/cost-center/update/{id}.
 *
 * @param id cost center id (Required)
 */
export function updateCostCenter(id, params) {
  const url = `/cost-centers/${id}`;
  return from(AuthHttp.patch(url, params)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * DELETE /cost-centers
 * @param ids id of cost center (Required)
 */
export function deleteCostCenters(ids) {
  const url = `/cost-centers?listCostCenterId=${ids}`;
  return from(AuthHttp.delete(url, {})).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * POST /cost-centers
 * Add new cost center
 * @param
 */
export function addCostCenter(params) {
  return from(AuthHttp.post("/cost-centers", params));
}
