import { openNotification } from "@common/helpers";
import { AuthHttp } from "@common/services";
import { getRequestParams } from "@helpers";
import { from, of } from "rxjs";
import { catchError, map, concatMap } from "rxjs/operators";
import { SAFE_API_URL } from "@common/config";

export function getWebhookIntegrationList() {
  return from(AuthHttp.get(`/integrate-webhook`)).pipe(
    map((res) => {
      return res.data;
    }),
    catchError((error) => {
      return of([]);
    })
  );
}
export function addWebhookIntegration(data) {
  return from(
    AuthHttp.post(
      `/integrate-webhook`, data
    )
  ).pipe(
    catchError((error) => {
      return of([]);
    })
  );
}
export function updateWebhookIntegration(id,data) {
  return from(
    AuthHttp.put(
      `/integrate-webhook/${id}`, data
    )
  ).pipe(
    catchError((error) => {
      return of([]);
    })
  );
}

export function deleteWebhookIntegrations(id) {
  const url = `/integrate-webhook/${id}`;
  return from(AuthHttp.delete(url, {})).pipe(
    catchError((error) => {
      return of({});
    })
  );
}

/**
 * POST /business-unit
 * Create business unit
 * @param
 */
export function createBU(params) {
  return from(AuthHttp.post("/business-units", params)).pipe(
    map(({ data, messages }) => {
      return from(
        AuthHttp.post(
          `${process.env.REACT_APP_SAFE_API_URL}/v1/accountgroup/default-compliance`,
          { id: data?.id }
        )
      );
    })
  );
}

/**
 * GET /api/cost-centers
 * Get cost center list
 */
export function getCostCenters(companyId) {
  const url = `/cost-centers?companyId=${companyId}`;
  return from(AuthHttp.get(url)).pipe(
    map((res) => {
      if (res.data && !res.data.length) {
        openNotification("error", "Cost center list is empty!");
        return [];
      }
      return res.data.map(({ id, name }) => ({ id, name }));
    }),
    catchError((e) => {
      return of([]);
    })
  );
}

/**
 * GET /api/members
 * Get member list
 */
export function getLoginCostCenterMember(companyId) {
  const url = `users/login-costcenter-members`;
  return from(AuthHttp.get(url)).pipe(
    map((res) => {
      return res.data;
    }),
    catchError((error) => {
      return of([]);
    })
  );
}

/**
 * POST /business-unit/add-members
 * Add members of business unit
 * @param
 */
export function addMembers(buId, userIds, roleId) {
  return from(
    AuthHttp.post(
      `/business-units/addMember?buId=${buId}&userIds=${userIds}&roleId=${roleId}`,
      {}
    )
  ).pipe(
    catchError((error) => {
      return of([]);
    })
  );
}

/**
 * GET /api/business-units/:id/users
 * Get all user of BU
 * @param buId id of business units (Required)
 */
export function getUsersBU(id, params) {
  const requestParams = getRequestParams(params);
  const url = `/users/${id}/bu-members?${requestParams}`;
  return from(AuthHttp.get(url)).pipe(
    map(({ data }) => {
      return { data: data.content, count: data.totalElements };
    }),
    catchError((e) => {
      return of({ data: [], count: 0 });
    })
  );
}

/**
 * DELETE /business-units/users
 * @param ids id of user of BU (Required)
 */
export function removeUsersBU(buId, ids) {
  const url = `/business-units/removeMember?buId=${buId}&userIds=${ids}`;
  return from(AuthHttp.delete(url, {})).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * GET /api/business-unit/:id.
 *
 * @param id ID of Business Unit (Required)
 */
export function getWebhookIntegrationDetails(id) {
  const url = `/business-units/${id}`;
  return from(AuthHttp.get(url)).pipe(
    map(({ data }) => {
      return data;
    }),
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * POST /business-unit/:id
 * Edit Business Unit
 * @param id ID of Business Unit (Required)
 * @body {name: '', description: ''} (Required)
 */
export function updateBUDetails(id, params) {
  return from(AuthHttp.put(`/business-units/${id}`, params)).pipe(
    catchError((error) => {
      return of({});
    })
  );
}

/**
 * Update /api/public/business-units/setDefaultBu?buId={buId}&userId={userId}
 *
 * @param userId User Id (Required)
 * @param buId Business Unit Id (Required)
 */
export function setDefaultBU(params) {
  const url = `/business-units/setDefaultBu`;
  return from(AuthHttp.patch(url, params)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}
