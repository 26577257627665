import {
  COMMON_LOAD_USER_INFO_ACTION_TYPE,
  LOAD_USER_PROFILE_ACTION_TYPE,
  LOAD_USER_PROFILE_DETAIL_ACTION_TYPE,
  SELECT_COST_CENTER,
  SELECT_MULTI_COST_CENTER,
  PERMISSION_BY_BU,
} from "@constants/ActionTypes";
import { SELECT_BUSINESS_UNIT } from "../../constants";

const INIT_STATE = {
  company: {},
  costCenter: [],
  businessUnits: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMMON_LOAD_USER_INFO_ACTION_TYPE:
      return {
        ...state,
        roles: action.payload.roles,
        name: action.payload.name,
        userName: action.payload.userName,
        email: action.payload.email,
      };
    case LOAD_USER_PROFILE_ACTION_TYPE:
      return {
        ...state,
        company: action.payload.company,
        costCenter: action.payload.costCenter,
        businessUnits: action.payload.businessUnits,
        selectedCostCenter: action.payload.costCenter
          ? action.payload.costCenter.id
          : null,
      };
    case LOAD_USER_PROFILE_DETAIL_ACTION_TYPE:
      return {
        ...state,
        detail: action.payload.detail,
      };
    case SELECT_COST_CENTER:
      return {
        ...state,
        selectedCostCenter: action.payload,
      };
    case SELECT_MULTI_COST_CENTER:
      return {
        ...state,
        selectedMultiCostCenter: action.payload,
      };
    case SELECT_BUSINESS_UNIT:
      return {
        ...state,
        selectedBU: action.payload,
      };
    case PERMISSION_BY_BU:
      return {
        ...state,
        permissionByBU: action.payload,
      };
    default:
      return state;
  }
};
