import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Layout, Menu } from "antd";
import UserMenu from "@components/UserMenu";
import { useSelector } from "react-redux";
import { login } from "@common/auth";

const { Header } = Layout;

const Topbar = () => {
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const isLoggedIn = (user) => user && user.roles;

  const menuKey = ["dashboard", "resource", "cost", "security", "identity"];
  return (
    <div className="akac-header">
      <Header
        className="akac-header-container"
        style={{ justifyContent: "space-between" }}
      >
        <div></div>
        <Menu
          mode="horizontal"
          selectedKeys={"identity"}
          style={{ minWidth: 600 }}
        >
          <Menu.Item key="dashboard">
            <a href={`${process.env.REACT_APP_PORTAL}/dashboard`}>
              {t("COMMON.0069")}
            </a>
          </Menu.Item>
          <Menu.Item key="resource">
            <a href={`${process.env.REACT_APP_PORTAL}/resource`}>
              {t("COMMON.0070")}
            </a>
          </Menu.Item>
          <Menu.Item key="cost">
            <a href={`${process.env.REACT_APP_EXPENSE}/dashboard`}>
              {t("COMMON.0071")}
            </a>
          </Menu.Item>
          <Menu.Item key="security">
            <a
              href={`${process.env.REACT_APP_SECURITY_ASSURANCE}/compliance/standards`}
            >
              {t("COMMON.0072")}
            </a>
          </Menu.Item>
          <Menu.Item key="ops">
            <a
              href={`${process.env.REACT_APP_CLOUD_OPERATION}/service-catalog`}
            >
              {t("ROLE.0017")}
            </a>
          </Menu.Item>
          <Menu.Item key="identity">
            <a
              href={`${process.env.REACT_APP_IDENTITY}/user-area/cloud-accounts/aws`}
            >
              {t("COMMON.0073")}
            </a>
          </Menu.Item>
        </Menu>
        <div className="akac-header-right-panel" style={{ display: "flex" }}>
          <UserMenu />
        </div>
      </Header>
    </div>
  );
};
export default Topbar;
