import { from, of } from "rxjs";
import { AuthHttp } from "@common/services";
import { catchError, concatMap, map } from "rxjs/operators";
import { openNotification } from "@common/helpers";
import { getRequestParams } from "@helpers";

/**
 * GET /roles/:roleId/permissions
 *
 * @param roleID
 */
export function getRolePermissionsAPI(roleId) {
  const url = `/roles/${roleId}/permissions`;
  return from(AuthHttp.get(url));
}

/**
 * GET /roles/:roleId/members
 *
 * @param roleID
 */
export function getAllMemberAPI(roleId) {
  const url = `/roles/${roleId}/members`;
  return from(AuthHttp.get(url));
}

/**
 * POST /roles/:roleId/members
 *
 * @param roleID
 * @param listUserId
 */
export function inviteMemberAPI(roleId, listUserId) {
  const url = `/roles/${roleId}/members`;
  return from(AuthHttp.post(url, listUserId));
}

/**
 * PUT /roles/:roleId/permissions
 *
 * @param roleID
 * @param permissions
 */
export function putPermissionsAPI(roleId, permissions) {
  const url = `/roles/${roleId}/permissions`;
  return from(AuthHttp.put(url, permissions));
}

/**
 * DELETE /roles/:ids
 * @param ids id of cloud account of BU (Required)
 */
export function deleteUserRoles(ids) {
  const url = "/roles";
  return from(AuthHttp.delete(url, { data: ids })).pipe(
    catchError((error) => {
      return of({});
    })
  );
}

/**
 * DELETE /roles/:ids
 * @param ids id of cloud account of BU (Required)
 */
export function deleteMembers(roleId, ids) {
  const url = `/roles/${roleId}/members`;
  return from(AuthHttp.delete(url, { data: ids })).pipe(
    catchError((error) => {
      return of({});
    })
  );
}

/**
 * POST
 *
 * @param body
 */
export function createRoles(name, buId, member, description) {
  const url = `/roles`;
  const observable = from(AuthHttp.post(url, { name, buId, description }));
  if (member?.length > 0) {
    return observable.pipe(
      concatMap(({ data, messages }) => {
        const roleId = data.id;
        return inviteMemberAPI(roleId, member);
      }),
      catchError((error) => {
        return of({});
      })
    );
  }
  return observable;
}

/**
 * GET /roles
 *
 */
export function getRoleList(params) {
  const requestParams = getRequestParams(params);
  const url = `/roles/summary?${requestParams}`;
  return from(AuthHttp.get(url)).pipe(
    map(({ data }) => {
      return { data: data.content, total: data.totalElements };
    }),
    catchError((e) => {
      return of({ data: [], total: 0 });
    })
  );
}
