import {
  SET_CLOUD_ACCOUNTS,
  SELECT_CLOUD_ACCOUNT,
  SELECT_PROVIDER,
} from "@constants/ActionTypes";

export const setAccounts = (accounts) => {
  return {
    type: SET_CLOUD_ACCOUNTS,
    payload: accounts,
  };
};

export const selectAccount = (account) => {
  return {
    type: SELECT_CLOUD_ACCOUNT,
    payload: account,
  };
};

export const selectProvider = (provider) => {
  return {
    type: SELECT_PROVIDER,
    payload: provider,
  };
};
