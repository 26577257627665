import { from, of } from "rxjs";
import { AuthHttp } from "@common/services";
import { map, catchError } from "rxjs/operators";
import { openNotification } from "@common/helpers";
import { getRequestParams } from "@helpers";

/**
 * DELETE api/users
 * Delete user
 * @param userIds User ids (Required)
 */
export function deleteUsers(userIds) {
  const url = "/users";
  return from(AuthHttp.delete(url, { data: userIds }));
}

/**
 * GET /api/users
 * Get user list
 * @param page page index (Optional)
 * @param limit page size (Optional)
 * @param keyword search by keyword (Optional)
 * @param sortBy sort by field (Optional)
 * @param sortOrder sort by ASC/ DESC (Optional)
 */
export function getUserList(params) {
  const requestParams = getRequestParams(params);
  const url = `/users?${requestParams}`;
  return from(AuthHttp.get(url)).pipe(
    map(({ data }) => {
      return { data: data.content, count: data.totalElements };
    }),
    catchError((e) => {
      return of({ data: [], count: 0 });
    })
  );
}

/**
 * POST /api/users/invitation
 * Invite User
 * @body (Required)
 * {
 * "email": "string",
 * "randomPassword": "string",
 * "roleCode": "string",
 * "buId": "string"
 * }
 */
export function inviteUsers(body) {
  const url = "/users/invitation";
  return from(AuthHttp.post(url, body)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * GET /api/public/user/profile.
 * Get User Profiles to support Display when login
 */
export function getUserProfile() {
  const url = "/users/profile";
  return from(AuthHttp.get(url)).pipe(map((res) => res.data));
}

/**
 * GET /api/public/user/profile.
 * Get User Profiles to support Display when login
 */
export function getUserInfo() {
  const url = "/users/info";
  return from(AuthHttp.get(url)).pipe(
    map((res) => res.data),
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * PUT /api/public/user/profile.
 * Update User Profiles
 */
export function updateUserProfile(body) {
  const url = "/users/info";
  return from(AuthHttp.put(url, body)).pipe(
    map((res) => res.data),
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * GET /api/public/user/details/{userId}.
 * Get user details to support editing
 * @param userId User id (Required)
 */
export function getUserDetails(id) {
  const url = `/users/${id}`;
  return from(AuthHttp.get(url)).pipe(
    map((res) => {
      res.data.buId = res.data.businessUnits
        ? res.data.businessUnits.map((bu) => bu.id)
        : [];
      return res.data;
    }),
    catchError((e) => of({}))
  );
}

/**
 * GET /api/public/user/details/{userId}.
 *
 * @param userId User id (Required)
 */
export function getBusinessUnits() {
  const url = "/business-units";
  return from(AuthHttp.get(url)).pipe(
    map((res) => res.data.map(({ id, name }) => ({ id, name }))),
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * Update /api/public/user/{userId}/change-bu?buId={buId}
 *
 * @param userId User Id (Required)
 * @param buId Business Unit Id (Required)
 */
export function updateUser(userId, buId) {
  const url = `/users/${userId}/change-bu?buId=${buId}`;
  return from(AuthHttp.put(url)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}

/**
 * PUT /change-password
 *
 * @param body
 * {
      "currentPassword":"",
      "newPassword":"",
      "confirmNewPassword":""
    }
 */
export function changePassword(body) {
  const url = `/users/change-password`;
  return from(AuthHttp.put(url, body)).pipe(
    catchError((e) => {
      return of({});
    })
  );
}
