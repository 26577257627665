import { TeamOutlined, SettingOutlined } from "@ant-design/icons";
import appIcon from "@assets/images/icons/App.svg";
import auditLogIcon from "@assets/images/icons/Audit log.svg";
import buIcon from "@assets/images/icons/BU icon.svg";
import cloudIcon from "@assets/images/icons/Cloud.svg";
import companyIcon from "@assets/images/icons/Company.svg";
import costCenterIcon from "@assets/images/icons/Cost center.svg";
import logIcon from "@assets/images/icons/Log icon.svg";
import logoIcon from "@assets/images/icons/logo.svg";
import menuIcon from "@assets/images/icons/menu.svg";
import roleIcon from "@assets/images/icons/Role icon.svg";
import userIcon from "@assets/images/icons/User.svg";
import identityIcon from "@assets/images/icons/identity-logo-link.svg";
import expenseIcon from "@assets/images/icons/expense-logo-link.svg";
import safeIcon from "@assets/images/icons/safe-logo-link.svg";
import ssoIcon from "@assets/images/icons/SSO.svg";
import onboardIcon from "@assets/images/icons/onboard-logo-link.svg";
import React from "react";

export const SIDEBAR_MENU = [
  {
    id: "User-Area",
    text: "MENU_0003",
    icon: <TeamOutlined />,
    visible: true,
    userType: "CUSTOMER",
    children: [
      {
        text: "CLOUD.0001",
        url: "/user-area/cloud-accounts/aws",
        key: "1",
        icon: cloudIcon,
        visible: true,
      },
      {
        text: "COMMON.0031",
        url: "/user-area/users",
        key: "2",
        icon: userIcon,
        visible: true,
      },
      {
        text: "COMMON.0036",
        url: "/user-area/business-units",
        key: "3",
        icon: buIcon,
        visible: true,
      },
      {
        text: "COMMON.0043",
        url: "/user-area/cost-center",
        key: "4",
        icon: costCenterIcon,
        visible: true,
      },
      {
        text: "ROLE.0009",
        url: "/user-area/role",
        key: "5",
        icon: roleIcon,
        visible: true,
      },
      {
        text: "MENU_0002",
        url: "/user-area/setup-sso",
        key: "7",
        icon: ssoIcon,
        visible: true,
        isOwner: true,
      },
      {
        text: "MENU_0004",
        url: "/user-area/webhook-integration",
        key: "8",
        iconSVG: <SettingOutlined />,
        visible: true,
        isOwner: true,
      },
    ],
  },
  {
    id: "Admin-Area",
    text: "Admin Area",
    icon: <TeamOutlined />,
    visible: false,
    userType: "ADMIN",
    children: [
      {
        text: "User Management",
        url: "/admin-area/user",
        key: "1",
        icon: userIcon,
        visible: false,
      },
      {
        text: "Company",
        url: "/admin-area/company",
        key: "2",
        icon: companyIcon,
        visible: false,
      },
      {
        text: "Application",
        url: "/admin-area/application",
        key: "3",
        icon: appIcon,
        visible: false,
      },
      {
        text: "Role",
        url: "/admin-area/role",
        key: "4",
        icon: roleIcon,
        visible: false,
      },
      {
        text: "Audit Log",
        url: "/admin-area/audit-log",
        key: "5",
        icon: auditLogIcon,
        visible: false,
      },
    ],
  },
];

export const MENU_NAVIGATION = [
  {
    id: "expense",
    text: "Cloud Expense",
    icon: expenseIcon,
    url: process.env.REACT_APP_EXPENSE,
  },
  {
    id: "security",
    text: "Cloud Safe",
    icon: safeIcon,
    url: process.env.REACT_APP_SECURITY_ASSURANCE,
  },
  {
    id: "identity",
    text: "Cloud Identity",
    icon: identityIcon,
    url: process.env.REACT_APP_IDENTITY,
  },
];

export const MENU_CODE = {
  CLOUD_ACCOUNT: "identity_cloud_accounts",
  ROLE: "identity_role",
  USER: "identity_user",
  BU: "identity_bu",
  SSO: "identity_sso",
};

export const ROLE_MENU_IDENTITY = [
  {
    key: 1,
    code: "identity_cloud_accounts",
  },
  {
    key: 2,
    code: "identity_user",
  },
  {
    key: 3,
    code: "identity_bu",
  },
  {
    key: 4,
    code: "identity_role",
  },
  {
    key: 5,
    code: "identity_sso",
  },
];

export const ROLE_MENU_EXPENSE = [
  {
    key: 0,
    code: "expense_costLens",
  },
  {
    key: 1,
    code: "expense_billing",
  },
  {
    key: 2,
    code: "expense_budget",
  },
  {
    key: 3,
    code: "expense_policy",
  },
  {
    key: 4,
    code: "expense_underutilized",
  },
  {
    key: 5,
    code: "expense_reserveInstance",
  },
  {
    key: 6,
    code: "expense_tagPolicy",
  },
  {
    key: 7,
    code: "expense_tagMapping",
  },
];
export const ROLE_MENU_OPS = [
  {
    key: 1,
    code: "ops_vm",
  },
  {
    key: 2,
    code: "ops_schedules",
  },
  {
    key: 3,
    code: "ops_template",
  },
  {
    key: 4,
    code: "ops_deployment",
  },
];
