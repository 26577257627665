import { from } from "rxjs";
import { AuthHttp } from "@common/services";

/**
 * GET /roles
 *
 */
export function getAllRoleAPI(buId) {
  let url = "/roles";
  if (buId) {
    url = `/roles?buId=${buId}`;
  }
  return from(AuthHttp.get(url));
}
