import axios from "axios";

import { V1_API_URL } from "../config";
import { getAccessToken } from "../helpers";
import { openNotification } from "@common/helpers";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
const authHttp = axios.create({
  baseURL: V1_API_URL,
});

authHttp.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getAccessToken();
    const businessUnit = localStorage.getItem("businessUnit");
    const costCenterId = localStorage.getItem("costCenterId");
    const companyId = localStorage.getItem("companyId");
    if (businessUnit) {
      config.headers["x-dxc-buid"] = businessUnit;
    }
    if (costCenterId) {
      config.headers["x-dxc-costcenterid"] = costCenterId;
    }
    if (companyId) {
      config.headers["x-dxc-companyid"] = companyId;
    }
    config.headers["x-dxc-appCode"] = "CLOUD_IDENTITY";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authHttp.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 403) {
      Modal.warning({
        title: "Warning",
        content: "No permission",
      });
    } else {
      openNotification(
        "error",
        error.response.data.message
          ? error.response.data.message
          : error.response.data.messages
          ? error.response.data.messages[0].message
          : error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : "Something went wrong! Please try again later!"
      );
    }
    return Promise.reject(error);
  }
);

export default authHttp;
