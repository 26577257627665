import { from } from "rxjs";
import { AuthHttp } from "@common/services";
import { map } from "rxjs/operators";
import { getRequestParams, getAccessToken } from "@helpers";
/**
 * GET /api/public/cloudAccount/{accountId}.
 *
 * @param accountId Cloud Account id (Required)
 */
export function getAccount(accountId) {
  const url = `/cloud-accounts/${accountId}`;
  return from(AuthHttp.get(url)).pipe(
    map((res) => {
      if (res.data != null) {
        let data = Object.assign(res.data, res.data.cspCredential);
        delete data.cspCredential;
        return data;
      }
      return {};
    })
  );
}

/**
 * GET /api/public/cloudAccount/list.
 *
 * @param _page page index (Optional)
 * @param _pageSize page size (Optional)
 * @param _csp Cloud Account type (Optional)
 * @param _app app type (Optional)
 * @param _keyWord key Word to search (Optional)
 */
export function getAccountList(params) {
  const requestParams = getRequestParams(params);
  const url = `/cloud-accounts?${requestParams}`;
  return from(AuthHttp.get(url)).pipe(
    map((res) => {
      if (res.data != null) {
        res.data = res.data.map((item) => {
          let newArr = Object.assign(item, item.cspCredential);
          delete newArr.cspCredential;
          return newArr;
        });
      }
      return res;
    })
  );
}

/**
 * POST /cloud-accounts
 * Add cloud account
 * @param
 */
export function addAccount(params) {
  return from(AuthHttp.post("/cloud-accounts", params));
}

/**
 * Update /api/public/cloudAccount/update/{accountId}.
 *
 * @param accountId Cloud Account id (Required)
 */
export function updateAccount(accountId, params) {
  const url = `/cloud-accounts/${accountId}`;
  return from(AuthHttp.patch(url, params));
}

/**
 * DELETE /cloud-accounts
 * Add cloud account
 * @param accountIds Cloud Account ids (Required)
 */
export function deleteAccount(accountIds) {
  return from(AuthHttp.delete(`/cloud-accounts?accountIds=${accountIds}`));
}
/**
 * MOVE /cloud-accounts
 * move cloud account to other bu
 * @param accountIds Cloud Account ids (Required)
 * @param buId BU ID (Required)
 */
export function moveAccount(accountIds, buId) {
  return from(
    AuthHttp.patch(`/cloud-accounts?accountIds=${accountIds}&buId=${buId}`)
  );
}

/**
 * POST /cloud-accounts/upload-file
 * Upload file json
 * @param file
 */
export function uploadFile(file) {
  return from(AuthHttp.post("/cloud-accounts/upload", file));
}

export function getAWSRegions(accessKey, secretKey) {
  return from(
    AuthHttp.get(
      `/cloud-accounts/awsRegions?accessKey=${accessKey}&secretKey=${secretKey}`
    )
  );
}
export function getAzureRegions(cspCredential) {
  const requestParams = getRequestParams(cspCredential);
  return from(
    AuthHttp.get(
      `/cloud-accounts/azRegions?${requestParams}`)
  );
}
export function getGCPRegions(gcpJsonFile) {
    return from(
      AuthHttp.get(`/cloud-accounts/gcpRegions?gcpJsonFile=${gcpJsonFile}`)
    );
}
