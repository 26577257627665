// paraams: value of params is only string or number.
export function getRequestParams(params) {
  var requestParams = "";
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key] != null && params[key] !== "") {
        if (requestParams != "") {
          requestParams += "&";
        }
        requestParams += key + "=" + params[key];
      }
    });
  }
  return requestParams;
}
