import { useTranslation } from "react-i18next";
import {
  onNavStyleChange,
  toggleCollapsedSideNav,
} from "@appRedux/actions/Setting";
import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_FIXED,
} from "@constants/ThemeSetting";
import { Col, Spin, Menu, Row, Tooltip } from "antd";
import {
  DoubleRightOutlined,
  DoubleLeftOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import CustomScrollbars from "@util/CustomScrollbars";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { SIDEBAR_MENU, MENU_NAVIGATION } from "@constants/Menu";
import SidebarLogo from "./SidebarLogo";
import { PUBLIC_URL } from "@common/config";

const SubMenu = Menu.SubMenu;

const SidebarContent = () => {
  const { t } = useTranslation();
  const userType = useSelector((state) => state.user.detail.userType);
  const { companyOwner } = useSelector((state) => state.user.detail);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  let { pathname } = useSelector(({ common }) => common);
  const { navCollapsed } = useSelector(({ common }) => common);
  let navStyle = navCollapsed ? NAV_STYLE_MINI_SIDEBAR : NAV_STYLE_FIXED;

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));

    dispatch(
      onNavStyleChange(
        navStyle === NAV_STYLE_FIXED ? NAV_STYLE_MINI_SIDEBAR : NAV_STYLE_FIXED
      )
    );
  };

  const [state, setState] = useState({
    currentMenu: "",
    currentOpenMenu: "",
    openKeys: "",
    currentSubMenu: "",
  });

  const handleOpenSubmenu = (e) => {
    const currentOpenMenu = e.key.replace(/\/\s*$/, "").split("/");
    setState({
      ...state,
      currentSubMenu: e.key,
      currentOpenMenu: currentOpenMenu[1],
    });
  };

  const [menu, setMenu] = useState(SIDEBAR_MENU);
  const [loading, setLoading] = useState(true);

  const subscriptions = [];
  useEffect(() => {
    let allowList = [];

    let sidebarWithRoles = SIDEBAR_MENU;
    if (loading) {
      for (let group of sidebarWithRoles) {
        if (group.userType == userType) {
          group.visible = true;
          for (let sub of group.children) {
            sub.visible = true;
            if (
              !companyOwner &&
              (sub.url === "/user-area/log" ||
                sub.url === "/user-area/cost-center")
            )
              sub.visible = false;
          }
        }
      }
      setMenu(sidebarWithRoles);
      setLoading(false);
      /*
      if(roles.length > 0)
      {
        let i = 0;
        for(let role of roles)
        {

          TODO: waiting for roles
          subscriptions.push(
            getRolePermissionsAPI(role).subscribe((res) => {
              i++;
              if(res.data.length > 0)
              {
                for(let sub of res.data)
                {
                  for(let resource of sub.resources)
                  {
                    if(resource.permissions.length > 0)
                    {
                      for(let permission of resource.permissions)
                      {
                        if(permission.isEnable)
                        {
                          allowList.push(sub.group + resource.resourceName);
                        }
                      }
                    }
                  }
                }
              }
              for(let group of sidebarWithRoles)
              {
                for(let sub of group.children)
                {
                  if(sub.visible)
                  {
                    group.visible = true;
                  }
                  else{
                    if(allowList.includes(group.text + sub.text))
                    {
                      group.visible = true;
                      sub.visible = true;
                    }
                  }
                }
              }
              setMenu(sidebarWithRoles);
              setLoading(false);
            },
            (err) => openNotification(
              'error',
              'Occurred error when get roles!'
            )
            )
          );
        }
      }
          */
    }
  });

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const defaultOpenKeys = SIDEBAR_MENU.map((menu) => {
    // const matches =
    //   menu.children &&
    //   menu.children.some((c) => `${PUBLIC_URL}${c.url}` === pathname);

    // if (matches) {
    //   return menu.id;
    // }
    // return null;
    return menu.id;
  }).filter((k) => k != null);

  const selectedKeys = SIDEBAR_MENU.reduce(
    (accumulator, currentValue) => accumulator.concat(currentValue.children),
    []
  )
    .filter((m) => pathname.includes(m.url))
    .map((e) => e.url);

  const menuNavigation = (
    <Menu>
      {MENU_NAVIGATION.map((item) => (
        <Menu.Item key={item.id}>
          <a href={item.url}>{t(item.text)}</a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Spin spinning={loading}>
      <div className="akac-sidebar-menu">
        <div className="akac-sidebar-top-menu">
          <SidebarLogo />
          <div className="application-title">
            <MenuOutlined
              style={{ paddingRight: "19px" }}
              onClick={() => onToggleCollapsedNav()}
            />
            <span className="application-name">{t("MENU_0001")}</span>
          </div>
          <div className="gx-sidebar-content">
            <CustomScrollbars className="akac-layout-sider-scrollbar">
              {navStyle === NAV_STYLE_FIXED && (
                <Menu
                  defaultOpenKeys={defaultOpenKeys}
                  selectedKeys={selectedKeys}
                  className="sidebar-menu"
                  theme="dark"
                  mode="inline"
                >
                  {menu.map((submenu) => (
                    <div>
                      {submenu.children
                        ? submenu.children.map((link) =>
                            (companyOwner && link.isOwner) ||
                            (!companyOwner && !link.isOwner) ? (
                              <Menu.Item
                                key={`${PUBLIC_URL}${link.url}`}
                                style={link.visible ? {} : { display: "none" }}
                                className={
                                  state.currentSubMenu.includes(
                                    `${PUBLIC_URL}${link.url}`
                                  ) && "submenu-item-selected"
                                }
                              >
                                <Link to={`${PUBLIC_URL}${link.url}`}>
                                  <span>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "34px",
                                      }}
                                    >
                                      {link?.iconSVG ? (
                                        link?.iconSVG
                                      ) : (
                                        <img alt="" src={link.icon} />
                                      )}
                                    </span>
                                    <span>{t(link.text)}</span>
                                  </span>
                                </Link>
                              </Menu.Item>
                            ) : null
                          )
                        : null}
                    </div>
                  ))}
                </Menu>
              )}
              {navStyle === NAV_STYLE_MINI_SIDEBAR && (
                <Menu
                  defaultOpenKeys={defaultOpenKeys}
                  selectedKeys={selectedKeys}
                  className="sidebar-menu"
                  theme="dark"
                  mode="inline"
                >
                  {menu.map((submenu) => {
                    let subMenus = [];
                    submenu.children.map((link) => {
                      const sub =
                        companyOwner ||
                        (!companyOwner && !link.isOwner) ? (
                          <SubMenu
                            key={link.id}
                            style={link.visible ? {} : { display: "none" }}
                            title={
                              <span
                                className={
                                  location.pathname.includes(
                                    `${PUBLIC_URL}${link.url}`
                                  )
                                    ? "ant-menu-icon-selected"
                                    : ""
                                }
                                onClick={() =>
                                  history.push(`${PUBLIC_URL}${link.url}`)
                                }
                              >
                                <Tooltip placement="left" title={t(link.text)}>
                                  {link?.iconSVG ? (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        padding: "20px 30px",
                                      }}
                                    >
                                      {link?.iconSVG}
                                    </span>
                                  ) : (
                                    <img alt="" src={link.icon} />
                                  )}
                                </Tooltip>
                              </span>
                            }
                          ></SubMenu>
                        ) : null;
                      subMenus.push(sub);
                    });
                    subMenus.push(<Menu.Divider />);

                    return subMenus;
                  })}
                </Menu>
              )}
            </CustomScrollbars>
          </div>
        </div>
        <Row className="footer-bottom" justify="center">
          <Col span={24} className="footer-content">
            {navStyle === NAV_STYLE_FIXED && (
              <>
                <span>&copy; {t("COMMON.0068")}</span>
                <DoubleLeftOutlined
                  className="footer-icon-fold"
                  onClick={() => onToggleCollapsedNav()}
                />
              </>
            )}
            {navStyle === NAV_STYLE_MINI_SIDEBAR && (
              <DoubleRightOutlined
                className="footer-icon-unfold"
                onClick={() => onToggleCollapsedNav()}
              />
            )}
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
