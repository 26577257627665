import { NAV_STYLE_FIXED } from "../../constants/ThemeSetting";
import { onNavStyleChange } from "../actions";

const portalLayoutHandler = (store) => (next) => (action) => {
  if (action.type === "@@router/LOCATION_CHANGE") {
    const { pathname } = action.payload.location;
    const { settings } = store.getState();
    const { navStyle } = settings;

    if (pathname != null) {
      if (navStyle !== NAV_STYLE_FIXED) {
        store.dispatch(onNavStyleChange(NAV_STYLE_FIXED));
      }
    }
  }

  next(action);
};

export default portalLayoutHandler;
