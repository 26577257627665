import "./shared/assets/vendors/style";
import "./shared/styles/wieldy.less";

import { ConnectedRouter } from "connected-react-router";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { history } from "./common/appRedux/store";
import App from "./shared/containers/App";

const NextApp = (props) => {
  const { store } = props;

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default NextApp;
