export const HOOK_AUTHORIZATION_TYPE = "HOOK_AUTHORIZATION_TYPE";

export const TARGET_OFFSET = 20;

export const AUTHORIZATION_CODE = {
  TokenMissing: 0,
  TokenExpired: 1,
  PermissionMissing: 2,
};

export * from "./ActionTypes";
export * from "./ThemeSetting";
export * from "./Budget";
export * from "./Account";
export * from "./Menu";
