import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "@assets/i18n/en.json";
import vi from "@assets/i18n/vi.json";
import ja from "@assets/i18n/ja.json";

i18n.use(LanguageDetector).init({
  debug: true,
  lng: "en_US",
  fallbackLng: "en_US", // use en if detected lng is not available
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  resources: {
    en_US: {
      translations: en,
    },
    vi: {
      translations: vi,
    },
    ja_JP: {
      translations: ja,
    },
  },
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    wait: true,
  },
});

export default i18n;
