import { saveAs } from "file-saver";
import XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

// dataTable is an array of arrays
export const exportToExcel = (dataTable, filename) => {
  const worksheet = XLSX.utils.aoa_to_sheet(dataTable);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: EXCEL_TYPE });

  saveAs(data, filename + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
};

// apply column and row filter to data
// returns an array of arrays
export const prepareData = (data, selectedRows, columns, selectedColumns) => {
  let preparedData = data;
  let columTitles = columns.map((col) => col.title);
  if (selectedRows && selectedRows.length > 0) {
    preparedData = selectedRows;
  }
  if (selectedColumns && selectedColumns.length > 0) {
    columTitles = selectedColumns.map((col) => col.title);
    preparedData = preparedData.map((row) =>
      selectedColumns.map((col) => row[col.key])
    );
  }
  return [columTitles, ...preparedData];
};
