import React, { useEffect, useState } from "react";
import { Avatar, Popover, Divider } from "antd";
import { logOut } from "@common/auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PUBLIC_URL } from "@common/config";
import { useSelector } from "react-redux";

const UserMenu = () => {
  const user = useSelector((state) => state.user.detail);
  const userMenuOptions = (
    <ul className="akac-user-popover">
      <li className="akac-header-dropdown-menu ">
        <Link to={`${PUBLIC_URL}/user-profile`}>My Profile</Link>
      </li>
      <Divider className="akac-divider" />
      <li onClick={logOut} className="akac-header-dropdown-menu ">
        Logout
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row akac-header-menu">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={require("@assets/images/ios-contact.svg")}
          className="gx-size-30 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name akac-header-menu-avatar">
          {user.userName}
          <i className="icon icon-chevron-down gx-fs-sm gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserMenu;
