const API_URL = process.env.REACT_APP_API_URL;
const V1_API_URL = `${API_URL}/api/v1`;
const PUBLIC_API_URL = `${API_URL}/api/public`;
const PUBLIC_URL = process.env.PUBLIC_URL;
const LANDING_URL = process.env.REACT_APP_LANDING_URL;
const RESOURCE_URL = process.env.REACT_APP_PORTAL;
const REACT_APP_IDENTITY_URL = process.env.REACT_APP_IDENTITY;
const REACT_APP_EXPENSE_URL = process.env.REACT_APP_EXPENSE;
const SAFE_API_URL = process.env.REACT_APP_SAFE_API_URL;

const SSO_CONFIGURES = {
  url: process.env.REACT_SSO_CONFIGURES_URL,
  redirectUri: process.env.REACT_SSO_CONFIGURES_REDIRECT_URL,
  realm: "akaclaud-Dev",
  clientId: "akaclaud-labs",
  onLoad: "login-required",
  "enable-cors": true,
  checkLoginIframeInterval: 900,
};

export {
  API_URL,
  V1_API_URL,
  PUBLIC_API_URL,
  SSO_CONFIGURES,
  PUBLIC_URL,
  LANDING_URL,
  RESOURCE_URL,
  REACT_APP_IDENTITY_URL,
  SAFE_API_URL,
  REACT_APP_EXPENSE_URL,
};
